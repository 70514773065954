import {Cache} from "@boomrank/react-components";
import {Account, Customer, InvoiceStatus, Language, LastType, Link, Provider, Quote} from "../../models";
import {LinkFragment} from "./fragments";
import {StandaloneLink} from "../../models/standalone";
import {getTranslation} from "../../intl";


export class LinkFactory {
    static fromFragment = (fragment: LinkFragment): Link => {
        let link = new Link()
        link.id = fragment.id
        link.createdAt = new Date(fragment.created_at)
        link.updatedAt = new Date(fragment.updated_at)
        link.importedAt = new Date(fragment.imported_at)
        link.key = fragment.key
        link.language = fragment.language as Language
        link.priceHt = fragment.price_ht
        link.url = fragment.url
        link.broken = fragment.broken
        link.underCorrection = fragment.under_correction
        link.invoiceStatus = fragment.invoice_status as InvoiceStatus

        link.isOk = fragment.is_ok

        link.targetExpression = fragment.target_expression
        link.targetRegex = fragment.target_regex
        link.targetContent = fragment.target_content
        link.targetUrl = fragment.target_url

        link.requestedRegex = fragment.requested_regex
        link.requestedContent = fragment.requested_content
        link.requestedUrl = fragment.requested_url

        link.isValid = fragment.is_valid
        link.isIndexed = fragment.is_indexed
        link.nbRedirect = fragment.nb_redirect

        link.lastHeaders = fragment.last_headers
        link.headersValid = fragment.headers_valid

        if (fragment.last_urls) {
            link.lastUrls = JSON.parse(fragment.last_urls)
        }
        link.urlValid = fragment.url_valid

        link.lastMeta = fragment.last_meta
        link.metaValid = fragment.meta_valid

        link.lastRel = fragment.last_rel
        link.relValid = fragment.rel_valid

        link.lastHttpCode = fragment.last_http_code
        link.httpCodeValid = fragment.http_code_valid

        link.lastContent = fragment.last_content
        link.contentValid = fragment.content_valid

        link.lastType = fragment.last_type as LastType
        if (fragment.last_update) {
            link.lastUpdate = new Date(fragment.last_update)
        }

        link.comment = fragment.comment

        link.accountId = fragment.account
        link.customerId = fragment.customer
        link.providerId = fragment.provider
        link.quoteId = fragment.quote
        return link
    }
}

export class StandaloneLinkFactory {
    static fromLink = (
        link: Link,
        cacheCustomers: Cache<Customer>,
        cacheProviders: Cache<Provider>,
        cacheAccounts: Cache<Account>,
        cacheQuotes: Cache<Quote>
    ): StandaloneLink => {
        let customers = cacheCustomers.get(link.customerId)
        let providers = cacheProviders.get(link.providerId)
        let accounts = cacheAccounts.get(link.accountId)

        let quoteRef = undefined
        if (link.quoteId) {
            let quotes = cacheQuotes.get(link.quoteId)
            if (quotes.length > 0) {
                quoteRef = quotes[0].ref
            }
        }
        let month = `0${link.createdAt.getMonth()+1}`.slice(-2)
        let period = `${link.createdAt.getFullYear()}-${month}`

        let standalone = {...link} as StandaloneLink

        if(customers.length > 0) {
            standalone.customerName = customers[0].name
        }
        if(providers.length > 0) {
            standalone.providerName = providers[0].name
        }
        if(accounts.length > 0) {
            standalone.userEmail = accounts[0].user.email

            let name = accounts[0].user.lastname
            let lastname = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
            let firstname = accounts[0].user.firstname.charAt(0)

            standalone.accountUser = `${lastname} ${firstname}.`
        }

        if (link.isOk) {
            standalone.validState = 0
        } else {
            if (link.isValid) {
                standalone.validState = 1
            }
            if (!link.broken && !link.isValid) {
                standalone.validState = 2
            }
            if (link.broken && !link.isValid) {
                standalone.validState = 3
            }
            if (link.underCorrection) {
                standalone.validState = 4
            }
        }

        standalone.quoteRef = quoteRef
        standalone.period = period
        standalone.languageName = getTranslation(`LANGUAGE.${link.language}.NAME`)
        return standalone
    }
}