import React, {useEffect, useState} from "react";

import {Link} from "../../../../models";
import {getTranslation} from "../../../../intl";
import {ResponsiveTextarea} from "@boomrank/react-components";


interface Props {
    link: Link
    onSubmit: (link: Link) => void,
    onError: (message: string) => void,
    disabled?: boolean
}

export function LinkCommentForm(props: Props) {
    let [link, setLink] = useState<Link>(props.link)

    let onSubmit = () => {
        props.onSubmit(link)
    }

    let onChangeTextarea = (name: string, value: string | number) => {
        setLink({
            ...link,
            [name]: value
        })
    }

    useEffect(() => {
        setLink(props.link)
    }, [props.link])


    return (
        <div className={'w-full bg-white p-3 mt-3 rounded-lg shadow-sm lg:w-1/3'}>
            <div className={'w-full mb-8 flex justify-between'}>
                <h3>{getTranslation('NETLINKING.FORM.LINK.LABEL_COMMENT')}</h3>

                <button
                    data-testid={'netlinking-form-link-comment-submit'}
                    className={'btn-blue p-2 rounded-md disabled:hover:bg-gray-100'}
                    onClick={onSubmit}
                >
                    Enregistrer
                </button>
            </div>

            <ResponsiveTextarea
                name={'comment'}
                value={link.comment}
                onChange={onChangeTextarea}
                dataTestId={'netlinking-form-link-comment'}
            />
        </div>
    )
}