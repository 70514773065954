import React, {useState} from "react";
import {FormErrorResponse, JwtToken, ToastData, ToastNotification} from "@boomrank/react-components";

import {Identifier, Link as Backlink} from "../../../../../models";

import {LinkBulkApi} from "../../../../../services/link/bulk/api";
import {LinkFactory} from "../../../../../services/link/factory";
import {LinkListResponse} from "../../../../../services/link/responses";

import {getTranslation} from "../../../../../intl";
import {ExportApi} from "../../../../../services/export/api";
import {CustomToastNotification} from "../../../../dummies/toast";


export interface Props {
    token: JwtToken
    links: Backlink[]
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    onUpdateLinks?: () => void
}

export function LinkBulkOperations(props: Props) {
    let toastInit = {success: false, message: '', show: false}

    let [toast, setToast] = useState<ToastData>(toastInit)
    let [showOptions, setShowOptions] = useState<boolean>(false)

    let [isLoading, setIsLoading] = useState<boolean>(false)


    let onSuccess = (res: FormErrorResponse | LinkListResponse, message: string) => {
        let response = res as LinkListResponse

        response.data.results.forEach((l) => {
            let backlink = LinkFactory.fromFragment(l)
            backlink.lastUpdate = new Date()
            props.cacheUpdater(backlink)
        })

        if (props.onUpdateLinks) {
            props.onUpdateLinks()
        }

        setToast({ success: true, message: message, show: true })
        setIsLoading(false)
    }

    let onError = (message: string = "Une erreur s'est produite ...") => {
        setToast({ success: false, message: message, show: true })
        setIsLoading(false)
    }

    let onLaunchCrawl = () => {
        setToast(toastInit)

        let confirm = window.confirm(
            `Voulez-vous relancer une analyse pour le(s) ${props.links.length} lien(s) sélectionné(s) ?\nCela devrait prendre quelques minutes.`
        )

        if (confirm) {
            if (process.env.NODE_ENV === "development") {
                props.links.forEach((link) => {
                    link.lastUpdate = new Date()
                    props.cacheUpdater(link)
                })

                let message = `Une analyse est prévue pour ${props.links.length} lien(s), cela peut prendre quelques minutes ...`
                setToast({ success: true, message: message, show: true })
                return
            }

            let linkIds = props.links.map((link) => link.id)
            LinkBulkApi.launchAnalyze(props.token, linkIds)
                .then(res => {
                    if (res.statusCode === 200) {
                        let message = `Une analyse est prévue pour ${linkIds.length} lien(s), cela peut prendre quelques minutes ...`
                        onSuccess(res, message)
                    } else {
                        onError()
                    }
                })
        }
    }

    let onIsIndexed = () => {
        setToast(toastInit)

        let confirm = window.confirm(
            `Voulez-vous vérifier l'indexation de ${props.links.length} lien(s) ?\nCela peut prendre quelques minutes.`
        )

        if (confirm) {
            setIsLoading(true)

            let linkIds = props.links.map((link) => link.id)
            LinkBulkApi.isIndexed(props.token, linkIds)
                .then(res => {
                    if (res.statusCode === 200) {
                        console.log(res)
                        let message = `Une vérification via isIndexed a été effectuée pour ${linkIds.length} lien(s)`
                        onSuccess(res, message)
                    } else {
                        onError()
                    }
                })
        }
    }

    let onUpdateValid = () => {
        setToast(toastInit)

        LinkBulkApi.updateValidLinks(props.token, props.links)
            .then(res => {
                if (res.statusCode === 200) {
                    let message = `${props.links.length} lien(s) marqué(s) comme valide.`
                    onSuccess(res, message)
                } else {
                    onError()
                }
            })
    }

    let onUpdateIndexed = () => {
        setToast(toastInit)

        LinkBulkApi.updateIndexedLinks(props.token, props.links)
            .then(res => {
                if (res.statusCode === 200) {
                    let message = `${props.links.length} lien(s) marqué(s) comme indexé.`
                    onSuccess(res, message)
                } else {
                    onError()
                }
            })
    }

    let onUpdateBroken = () => {
        setToast(toastInit)

        let confirm = window.confirm(getTranslation('NETLINKING.VIEW.LINK.MARK_AS_BROKEN.CONFIRM_JS'))

        if (confirm) {
            LinkBulkApi.updateBrokenLinks(props.token, props.links)
                .then(res => {
                    if (res.statusCode === 200) {
                        let message = `${props.links.length} lien(s) marqué(s) comme définitivement cassé.`
                        onSuccess(res, message)
                    } else {
                        onError()
                    }
                })
        }
    }

    let onUpdateUnderCorrectionLinks = () => {
        setToast(toastInit)

        LinkBulkApi.updateUnderCorrectionLinks(props.token, props.links)
            .then(res => {
                if (res.statusCode === 200) {
                    let message = `${props.links.length} lien(s) en attente du retour éditeur.`
                    onSuccess(res, message)
                } else {
                    onError()
                }
            })
    }

    let onExport = () => {
        ExportApi.csv(props.token, props.links)
    }

    let onOpenInTab = (openType: "detail" | "url" | "backlink") => {
        props.links.forEach((link) => {
            if (openType === "detail") {
                window.open(`/dashboard/netlinking/link/${link.id}`, "_blank", "noreferrer")
            }
            if (openType === "url") {
                window.open(link.url, "_blank", "noreferrer")
            }
            if (openType === "backlink") {
                window.open(link.targetUrl, "_blank", "noreferrer")
            }
        })
    }


    return (
        <div className={'w-fit flex justify-end'}>
            <ToastNotification toast={toast} />

            {
                isLoading &&
                <CustomToastNotification
                    toast={{
                        message: "Vérification de l'indexation en cours ...",
                        show: isLoading
                    }}
                />
            }

            <div className={'w-full sm:min-w-80 relative'}>
                <button
                    className={
                        "w-full flex-shrink-0 z-10 inline-flex items-center p-3 text-gray-500 " +
                        "bg-white border border-gray-100 rounded-md transition-all"
                    }
                    onClick={() => setShowOptions(!showOptions)}
                >
                    {
                        props.links.length > 1 ?
                            `Sélectionner une option (${props.links.length} liens)` :
                            'Sélectionner une option'
                    }
                </button>

                {
                    showOptions &&
                    <div className={"w-full z-10 bg-white rounded-lg shadow absolute top-16 p-1"}>
                        <button
                            onClick={onLaunchCrawl}
                            className={
                                "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                "mb-1 hover:bg-purple-50 hover:text-purple-400 transition-all " +
                                "disabled:bg-gray-50 disabled:cursor-not-allowed"
                            }
                            disabled={props.links.length === 0}
                        >
                            <span className={'w-5 mr-3 text-center'}>
                                <i className={"text-xs fa-solid fa-magnifying-glass-chart"} />
                            </span>
                            Lancer un crawl
                        </button>

                        {/*<button*/}
                        {/*    onClick={onIsIndexed}*/}
                        {/*    className={*/}
                        {/*        "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +*/}
                        {/*        "hover:bg-indigo-50 hover:text-indigo-400 transition-all " +*/}
                        {/*        "disabled:bg-gray-50 disabled:cursor-not-allowed"*/}
                        {/*    }*/}
                        {/*    disabled={props.links.length === 0}*/}
                        {/*>*/}
                        {/*    <span className={'w-5 mr-3 text-center'}>*/}
                        {/*        <i className={"text-xs fa-solid fa-asterisk"} />*/}
                        {/*    </span>*/}
                        {/*    Vérifier l'indexation*/}
                        {/*</button>*/}

                        <div className={'w-full h-0.5 my-2 bg-transparent rounded-md'} />

                        <button
                            onClick={onUpdateValid}
                            className={
                                "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                "mb-1 hover:bg-teal-50 hover:text-teal-400 transition-all " +
                                "disabled:bg-gray-50 disabled:cursor-not-allowed"
                            }
                            disabled={props.links.length === 0}
                        >
                            <span className={'w-5 mr-3 text-center'}>
                                <i className={"text-xs fa-solid fa-circle-check"} />
                            </span>
                            Marquer comme valide{props.links.length > 1 && 's'}
                        </button>
                        <button
                            onClick={onUpdateIndexed}
                            className={
                                "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                "mb-1 hover:bg-teal-50 hover:text-teal-400 transition-all " +
                                "disabled:bg-gray-50 disabled:cursor-not-allowed"
                            }
                            disabled={props.links.length === 0}
                        >
                            <span className={'w-5 mr-3 text-center'}>
                                <i className={"text-xs fa-solid fa-sitemap"} />
                            </span>
                            Marquer comme indexé{props.links.length > 1 && 's'}
                        </button>
                        <button
                            onClick={onUpdateUnderCorrectionLinks}
                            className={
                                "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                "mb-1 hover:bg-orange-50 hover:text-orange-400 transition-all " +
                                "disabled:bg-gray-50 disabled:cursor-not-allowed"
                            }
                            disabled={props.links.length === 0}
                        >
                            <span className={'w-5 mr-3 text-center'}>
                                <i className={"text-xs fa-solid fa-hourglass-half"} />
                            </span>
                            Marquer en attente du retour éditeur
                        </button>
                        <button
                            onClick={onUpdateBroken}
                            className={
                                "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg transition-all " +
                                "hover:bg-red-50 hover:text-red-400 disabled:bg-gray-50 disabled:cursor-not-allowed"
                            }
                            disabled={props.links.length === 0}
                        >
                            <span className={'w-5 mr-3 text-center'}>
                                <i className={"text-xs fa-solid fa-circle-xmark"} />
                            </span>
                            Marquer comme définitivement cassé{props.links.length > 1 && 's'}
                        </button>

                        {
                            props.links.length > 1 &&
                            <div className={'w-full h-0.5 my-2 bg-transparent rounded-md'} />
                        }

                        {
                            props.links.length > 1 &&
                            <button
                                onClick={() => onOpenInTab("detail")}
                                className={
                                    "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                    "hover:bg-cyan-50 hover:text-cyan-400 transition-all " +
                                    "disabled:bg-gray-50 disabled:cursor-not-allowed"
                                }
                            >
                                <span className={'w-5 mr-3 text-center'}>
                                    <i className={"text-xs fa-solid fa-arrow-up-right-from-square"} />
                                </span>
                                Ouvrir les pages détail
                            </button>
                        }

                        {
                            props.links.length > 1 &&
                            <button
                                onClick={() => onOpenInTab("backlink")}
                                className={
                                    "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                    "hover:bg-cyan-50 hover:text-cyan-400 transition-all " +
                                    "disabled:bg-gray-50 disabled:cursor-not-allowed"
                                }
                            >
                                <span className={'w-5 mr-3 text-center'}>
                                    <i className={"text-xs fa-solid fa-arrow-up-right-from-square"} />
                                </span>
                                Ouvrir les liens attendus
                            </button>
                        }

                        {
                            props.links.length > 1 &&
                            <button
                                onClick={() => onOpenInTab("url")}
                                className={
                                    "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                    "hover:bg-cyan-50 hover:text-cyan-400 transition-all " +
                                    "disabled:bg-gray-50 disabled:cursor-not-allowed"
                                }
                            >
                                <span className={'w-5 mr-3 text-center'}>
                                    <i className={"text-xs fa-solid fa-arrow-up-right-from-square"} />
                                </span>
                                Ouvrir les articles
                            </button>
                        }

                        {
                            props.links.length > 1 &&
                            <div className={'w-full h-0.5 my-2 bg-transparent rounded-md'} />
                        }

                        {
                            props.links.length > 1 &&
                            <button
                                onClick={onExport}
                                className={
                                    "w-full px-2 py-3 inline-flex items-center text-sm text-gray-700 rounded-lg " +
                                    "hover:bg-slate-50 transition-all " +
                                    "disabled:bg-gray-50 disabled:cursor-not-allowed"
                                }
                            >
                                <span className={'w-5 mr-3 text-center'}>
                                    <i className={"text-xs fa-solid fa-file-arrow-down"} />
                                </span>
                                Exporter la sélection
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    )
}